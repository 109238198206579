import { useMemo } from "react";
import { Slider } from "@blueprintjs/core";

import { InputConfig } from "../utils/firestore";
import {
  Strings,
} from "../utils/common";
import {
  ControlMode,
  IWriteControlConfigRequest,
  IWritePowerConfigResponse,
  InputMode,
} from "../utils/common-types";
import {
  LabelRendererCallableFn,
  LabelRendererFn,
  getThrottleLabelRenderer,
} from "../utils/throttle";
import "./ThrottleRangeDisplay.css";

const VALID_CONTROL_MODES = [
  ControlMode.TORQUE,
  ControlMode.SPEED,
  ControlMode.VOLTAGE,
  ControlMode.POSITION,
];

export const ThrottleRangeDisplay = ( props: {
  powerConfig: IWritePowerConfigResponse | Strings<IWritePowerConfigResponse>;
  controlConfig: IWriteControlConfigRequest | Strings<IWriteControlConfigRequest>; // for controlMode, motorCurrentLimit, motorMaxSpeed
  inputConfig: InputConfig | Strings<InputConfig>; // for inputMode & inputLimit{Lower,Center,Upper}
} ) => {
  const { 
    powerConfig,
    controlConfig,
    inputConfig,
  } = props;

  let min: number | undefined, max: number | undefined, center: number | undefined;
  let labelValues: number[] = [];
  let isValid = true; // Whether the slider should be colored to indicate it's valid.
  let labelRenderer: LabelRendererFn = () => "–";

  const isInputModeUSB = ( parseIntIfString( inputConfig.inputMode ) === InputMode.USB );

  const isDisplayingPhysicalUnits = isInputModeUSB;

  const controlMode: ControlMode | undefined = useMemo(() => {
    if ( !isDisplayingPhysicalUnits ) {
      return undefined;
    }
    const controlModeInt = parseIntIfString( controlConfig.controlMode );
    return ( VALID_CONTROL_MODES.indexOf( controlModeInt ) !== -1 ) ? controlModeInt : undefined;
  }, [controlConfig.controlMode, isDisplayingPhysicalUnits]);

  if ( isDisplayingPhysicalUnits ) {
    center = 0;
    // Invalid state below; will be overridden if valid.
    max = 1;
    isValid = false;

    if ( controlMode !== undefined ) {

      switch ( controlMode ) {
        case ControlMode.TORQUE:
          const motorCurrentLimit = parseFloatIfString( powerConfig.motorCurrentLimit );
          if ( isFinite( motorCurrentLimit ) ) {
            max = motorCurrentLimit;
            labelRenderer = getThrottleLabelRenderer( controlMode );
            isValid = true;
          } else {
            // New configuration, so max motor current is not yet specified.
            max = 1;
            labelRenderer = (value) => {
              if ( value > 0 ) return "limit A";
              if ( value === 0 ) return "0 A";
              return "-limit A";
            };
            isValid = true;
          }
          break;
        case ControlMode.SPEED:
          const motorMaxSpeed = parseFloatIfString( controlConfig.motorMaxSpeed );
          if ( isFinite( motorMaxSpeed ) ) {
            max = motorMaxSpeed;
            labelRenderer = getThrottleLabelRenderer( controlMode );
            isValid = true;
          }
          break;
        case ControlMode.VOLTAGE:
          // Special case here because battery voltage is in realtime data not the config.
          labelRenderer = ( value ) => {
            if ( value > 0 ) {
              return <span>+Batt&nbsp;V</span>;
            } else if ( value === 0 ) {
              return "0";
            } else {
              return <span>-Batt&nbsp;V</span>;
            }
          };
          isValid = true;
          break;
      }
    }

    // Prevent weird layout on maximum range of 0
    if ( max === 0 ) {
      max = 1;
      if ( labelRenderer !== undefined && typeof labelRenderer !== 'boolean' ) {
        const _labelRenderer = labelRenderer as LabelRendererCallableFn;
        labelRenderer = ( value => _labelRenderer( 0 ) );
      }
    }

    min = -max;
    labelValues = [ min, center, max ];

  } else if ( typeof inputConfig.inputLimitCenter === "string" ) {
    // PWM/CAN mode _and_ motor configuration is being edited.
    labelRenderer = true; // Bare integers, no units
    const {
      inputLimitLower,
      inputLimitCenter,
      inputLimitUpper,
    } = inputConfig as Strings<InputConfig>;
    const lowerGreaterThanCenter =
      inputLimitLower !== "" &&
      inputLimitCenter !== "" &&
      parseInt(inputLimitLower, 10) >
        parseInt(inputLimitCenter, 10);
    const centerGreaterThanUpper =
      inputLimitCenter !== "" &&
      inputLimitUpper !== "" &&
      parseInt(inputLimitCenter, 10) >
        parseInt(inputLimitUpper, 10);

    labelValues = [
      inputLimitLower,
      inputLimitUpper,
    ]
      .filter((v) => v !== "")
      .map((str) => parseInt(str, 10));
    if (
      inputLimitCenter !== "" &&
      !lowerGreaterThanCenter &&
      !centerGreaterThanUpper &&
      labelValues.length === 2
    ) {
      labelValues.push(parseInt(inputLimitCenter));
    }

    isValid = labelValues.length === 3 && !lowerGreaterThanCenter && !centerGreaterThanUpper;
    if ( inputLimitLower !== "" ) {
      min = parseInt(inputLimitLower);
    }
    if ( inputLimitUpper !== "" ) {
      max = parseInt(inputLimitUpper);
    }
    if ( inputLimitCenter !== "" ) {
      center = parseInt(inputLimitCenter);
    }

  } else {
    // PWM/CAN mode _and_ motor configuration is completed, valid, and saved.
    labelRenderer = true; // Bare integers, no units
    min = inputConfig.inputLimitLower as number;
    max = inputConfig.inputLimitUpper as number;
    center = inputConfig.inputLimitCenter as number;
    labelValues = [ min, max, center ];
  }

  if ( !isFinite( min! ) ) min = undefined;
  if ( !isFinite( max! ) ) max = undefined;
  if ( !isFinite( center! ) ) center = undefined;

  return (
    controlConfig.controlMode !== ControlMode.POSITION? <Slider
      className={`throttle-range-slider ${
        isValid ? "hidden-handle input-mapping-slider" : "hidden-handle"
      }`}
      min={min}
      max={max}
      value={center}
      labelValues={labelValues}
      showTrackFill={false}
      disabled={true}
      labelRenderer={labelRenderer}
    />
    : null
  )
}

const parseIntIfString = (input: string | number): number => {
  return ( typeof input === 'string' ? parseInt(input, 10) : input );
}

const parseFloatIfString = (input: string | number): number => {
  return ( typeof input === 'string' ? parseFloat(input) : input );
}
