import { useEffect, useMemo, useState } from "react";
import { Colors } from "../../design/colors";
import {
    FaultCode,
    ControllerState,
    IRealtimeDataMessage,
    PacketId,
    displayUnits
} from "../../utils/common-types";
import {
    camelCaseToReadable,
    controllerStateToString,
    faultCodeToString,
    throttle,
} from "../../utils/common";
import { REFRESH_MS, usePlayback } from "../../hooks/usePlayback";
import { IPacket } from "../../utils/packet.worker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import { packetThreadPoolApi } from "../../utils/packet-workers";

export interface IGaugeWidgetProps {
    close: () => void;
}

export interface IGaugeProps {
    packetKey: keyof IRealtimeDataMessage;
}

export const Gauge = ( props: IGaugeProps & IGaugeWidgetProps ): JSX.Element => {
    const { timeWindow } = usePlayback( PacketId.REALTIME_DATA );
    const [ latestValue, _setLatestValue ] = useState<number>();

    const fetchLatestValue = useMemo( () => throttle( async ( latestTimeMs: number ) => {
        let value: number | undefined;
        const packet = await packetThreadPoolApi.closestPacketBeforeTimestamp( PacketId.REALTIME_DATA, latestTimeMs );
        if ( packet !== null ) {
            value = ( packet as IPacket<PacketId.REALTIME_DATA> ).packet[ props.packetKey ] as number;
        }

        _setLatestValue( value );
    }, REFRESH_MS ), [ _setLatestValue, props.packetKey ] );

    useEffect( () => {
        const latestTimeMs = timeWindow?.[ 1 ];
        if ( latestTimeMs === undefined ) {
            _setLatestValue( undefined );
            return;
        }

        fetchLatestValue( latestTimeMs );
    }, [ timeWindow, fetchLatestValue ] );

    if ( latestValue === undefined ) {
        return <></>;
    }

    let text = `${ latestValue.toFixed( 3 ) } ${ displayUnits[ PacketId.REALTIME_DATA ][ props.packetKey ] }`;
    if ( props.packetKey === "controllerState" ) {
        if ( !isFinite( latestValue) || !( latestValue in ControllerState ) ) {
            // ToDo: I wish there was a way of also swapping the state when Gauges get swapped by drag-and-drop
            text = "---";
        } else {
            let controllerState: ControllerState = latestValue;
            text = controllerStateToString( controllerState );
        }
    } else if ( props.packetKey === "faultCodeDeprecated" ) {
        // TODO: remove this block after a few months of FaultControl widget in prod
        if ( !isFinite( latestValue) || !( latestValue in FaultCode ) ) {
            // ToDo: I wish there was a way of also swapping the state when Gauges get swapped by drag-and-drop
            text = "---";
        } else {
            let faultCode: FaultCode = latestValue;
            text = faultCodeToString( faultCode );
        }
    }

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", flex: "column" }}>
                    {/* Drag and drop drag handle */}
                    <FontAwesomeIcon
                        icon={ faGripVertical }
                        color={ Colors.WHITE }
                        style={{ padding: "0px 8px 0px 0px", cursor: "pointer" }}
                        size="lg"
                    />
                    <div style={{ color: Colors.WHITE }}>{ camelCaseToReadable( props.packetKey ) }</div>
                </div>
                <FontAwesomeIcon
                    icon={ faXmark }
                    color={ Colors.WHITE }
                    style={{ padding: "0px 4px", cursor: "pointer" }}
                    onClick={ props.close }
                    size="lg"
                />
            </div>
            <div style={{ display: "flex", flex: 1, fontSize: 48, color: Colors.WHITE, justifyContent: "center", alignItems: "center" }}>
                { text }
            </div>
        </div>
    );
};
