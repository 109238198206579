// Feature flags allow features to be merged into `dev` and testable on app-dev
// without automatically enabling them in production on the next merge to the
// `main` branch.
//
// Note that adding a new feature requires changes in each of three sections in this file.

import { Env, getEnv } from "./common";

// Once a feature is enabled in production and there is no possibility of
// disabling it, retire the feature by removing it here and carefully fixing
// all references to it in the code.

/////////////////////////////////////////////////////////////////////////
// Feature flag strings are defined below.
// Style:
//   No whitespace
//   All lower case
//   Only ASCII
//   Use _ not -
const SENSORLESS_FEATURE = 'sensorless';
const POSITION_LOCK_FEATURE = 'position_lock';
const CONFIG_STORE_FEATURE = 'config_store';

/////////////////////////////////////////////////////////////////////////
type FeatureFlagInfo = {
  description: string;
  enabledEnvironments: Env[];
};

// Edit the lines that set this map to enable or disable a feature in a given environment.
const FeatureFlagMap: Map<string, FeatureFlagInfo> = new Map([
  [
    SENSORLESS_FEATURE,
    {
      description: "When enabled, Sensorless HFI is available as a sensor mode.",
      enabledEnvironments: [ Env.Local, Env.Dev ],
    },
  ],
  [
    POSITION_LOCK_FEATURE,
    {
      description: "When enabled, Position Lock can be enabled on the Throttle tab.",
      enabledEnvironments: [ Env.Local, Env.Dev ],
    },
  ],
  [
    CONFIG_STORE_FEATURE,
    {
      description: "When enabled, the device configuration store can be managed on the Devices page.",
      enabledEnvironments: [ Env.Local, Env.Dev ],
    }
  ],
]);

// Exception to be thrown if not found in this map.
export class FeatureFlagNotInMapError extends Error { }

/////////////////////////////////////////////////////////////////////////
// Each feature flag should have an exported function that returns true if the feature is enabled, below.

// Example:
/*
export const isFooEnabled = (): boolean => {
  const info = FeatureFlagMap.get( FOO_FEATURE );
  if ( info === undefined ) {
    throw new FeatureFlagNotInMapError( FOO_FEATURE );
  }

  const currentEnv = getEnv();
  return info.enabledEnvironments.some( (enabledEnv) => enabledEnv === currentEnv );
};
*/

// If `env` is specified, the feature is checked for in a non-current environment
export const isSensorlessEnabled = ( env?: Env ): boolean => {
  const info = FeatureFlagMap.get( SENSORLESS_FEATURE );
  if ( info === undefined ) {
    throw new FeatureFlagNotInMapError( SENSORLESS_FEATURE );
  }

  if ( env === undefined ) {
    env = getEnv();
  }
  return info.enabledEnvironments.some( (enabledEnv) => enabledEnv === env );
};

// If `env` is specified, the feature is checked for in a non-current environment
export const isPositionLockEnabled = ( env?: Env ): boolean => {
  const info = FeatureFlagMap.get( POSITION_LOCK_FEATURE );
  if ( info === undefined ) {
    throw new FeatureFlagNotInMapError( POSITION_LOCK_FEATURE );
  }

  if ( env === undefined ) {
    env = getEnv();
  }
  return info.enabledEnvironments.some( (enabledEnv) => enabledEnv === env );
};


// If `env` is specified, the feature is checked for in a non-current environment
export const isConfigStoreEnabled = ( env?: Env ): boolean => {
  const info = FeatureFlagMap.get( CONFIG_STORE_FEATURE );
  if ( info === undefined ) {
    throw new FeatureFlagNotInMapError( CONFIG_STORE_FEATURE );
  }

  if ( env === undefined ) {
    env = getEnv();
  }
  return info.enabledEnvironments.some( (enabledEnv) => enabledEnv === env );
};
