import { useEffect, useMemo, useState } from "react";
import { Colors } from "../../design/colors";
import {
    FaultCode,
    IRealtimeDataMessage,
    PacketId,
} from "../../utils/common-types";
import {
    faultCodeToString,
    throttle,
} from "../../utils/common";
import { REFRESH_MS, usePlayback } from "../../hooks/usePlayback";
import { IPacket } from "../../utils/packet.worker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import { packetThreadPoolApi } from "../../utils/packet-workers";
import { Button } from "../Button";

export interface IFaultControlWidgetProps {
    close: () => void;
    clearAllFaults: () => void;
}

export interface IFaultControlProps {
    faultBitmapLowerPacketKey: keyof IRealtimeDataMessage;
    faultBitmapUpperPacketKey: keyof IRealtimeDataMessage;
}

export const FaultControl = ( props: IFaultControlProps & IFaultControlWidgetProps ): JSX.Element => {
    const { timeWindow } = usePlayback( PacketId.REALTIME_DATA );
    const [ latestFaultBitmapLower, _setLatestFaultBitmapLower ] = useState<number>();
    const [ latestFaultBitmapUpper, _setLatestFaultBitmapUpper ] = useState<number>();

    const fetchLatestValue = useMemo( () => throttle( async ( latestTimeMs: number ) => {
        let faultBitmapLower: number | undefined;
        let faultBitmapUpper: number | undefined;
        const packet = await packetThreadPoolApi.closestPacketBeforeTimestamp( PacketId.REALTIME_DATA, latestTimeMs );
        if ( packet !== null ) {
            faultBitmapLower = ( packet as IPacket<PacketId.REALTIME_DATA> ).packet[ props.faultBitmapLowerPacketKey ] as number;
            faultBitmapUpper = ( packet as IPacket<PacketId.REALTIME_DATA> ).packet[ props.faultBitmapUpperPacketKey ] as number;
        }

        _setLatestFaultBitmapLower( faultBitmapLower );
        _setLatestFaultBitmapUpper( faultBitmapUpper );
    }, REFRESH_MS ), [ _setLatestFaultBitmapLower, _setLatestFaultBitmapUpper, props.faultBitmapLowerPacketKey, props.faultBitmapUpperPacketKey ] );

    useEffect( () => {
        const latestTimeMs = timeWindow?.[ 1 ];
        if ( latestTimeMs === undefined ) {
            _setLatestFaultBitmapLower( undefined );
            _setLatestFaultBitmapUpper( undefined );
            return;
        }

        fetchLatestValue( latestTimeMs );
    }, [ timeWindow, fetchLatestValue ] );

    if ( latestFaultBitmapLower === undefined || latestFaultBitmapUpper === undefined ) {
        return <></>;
    }

    let faults = decodeBitmap( latestFaultBitmapLower ).concat(decodeBitmap( latestFaultBitmapUpper, 32 ) );

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", overflow: "auto" }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", flex: "column" }}>
                    {/* Drag and drop drag handle */}
                    <FontAwesomeIcon
                        icon={ faGripVertical }
                        color={ Colors.WHITE }
                        style={{ padding: "0px 8px 0px 0px", cursor: "pointer" }}
                        size="lg"
                    />
                    <div style={{ color: Colors.WHITE }}>Fault Control</div>
                </div>
                <FontAwesomeIcon
                    icon={ faXmark }
                    color={ Colors.WHITE }
                    style={{ padding: "0px 4px", cursor: "pointer" }}
                    onClick={ props.close }
                    size="lg"
                />
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <Button
                    style={{ marginTop: 4, width: "25%" }}
                    backgroundColor={ Colors.BUTTON_GREEN }
                    textColor={ Colors.WHITE }
                    onClick={ props.clearAllFaults }
                >
                Clear All Faults
                </Button>
            </div>
            <div style={{ display: "flex", flex: 1, fontSize: 32, color: Colors.WHITE, justifyContent: "center", alignItems: "center", overflow: "auto", paddingTop: 30 }}>
                <ul>
                    { faults.length === 0 ? "No faults" : faults.map((s) => <li>{s}</li>) }
                </ul>
            </div>
        </div>
    );
};

// Returns an array of human-readable fault code strings.
//
// The offset, if present, should be 32 to specify the upper 16 bits.
const decodeBitmap = (bitmap: number, offset?: number): string[] => {
    let codes: string[] = [];
    if (offset === undefined) offset = 0;
    let fc: keyof typeof FaultCode;
    for (fc in FaultCode) {
        if ( bitmap === 0 ) break;
        const n = Number(FaultCode[fc]);
        if ( !isFinite(n) || n < offset ) continue;
        const x = 1 << ( n - offset );
        if ( ( bitmap & x ) > 0 ) {
            codes.push(faultCodeToString(n as FaultCode));
            bitmap -= x;
        }
    }
    return codes;
};
